<template>
  <div class="collection-interview">
    <!--问答题-->
    <div class="essay-question">
      <h3>
        <span>面试问答</span>
        <span>
          <router-link to="/usr/practice">笔试题</router-link>
        </span>
      </h3>
      <!--收藏的题目-->
      <ul>
        <li v-for="i in 8" :key="i">
          <Qa with-read-count with-vote-count with-favorite-count/>
        </li>
      </ul>
      <!--分页-->
      <pagination/>
    </div>
  </div>
</template>

<script>
import Qa from '@/components/question/qa'
export default {
  components: { Qa }
}
</script>

<style scoped lang="scss">
  @import "../../../assets/style/variables";
  .collection-interview{
    .essay-question{
      padding: 0 20px;
      box-sizing: border-box;
      background-color: $background-color;
      box-shadow: $background-shadow;
      border-radius: 20px;
      h3{
        border-bottom: 1px solid #d8d8d8;
        margin: 0 0 25px 0;
        padding: 15px 10px 5px 10px;
        font-weight: normal;
        font-size: 16px;
        color: $font-color-third;
        span{
          margin-right: 25px;
          &:nth-of-type(1){
            color: $primary-color;
          }
        }
      }
      ul{
        margin: 0;
        padding: 0;
      }
      //分页
      .el-pagination{
        padding-bottom: 30px;
      }
    }
  }
</style>
